<template>
  <div class="friend-bottom-container">
    <div class="friend-bottom">
      <div
        v-if="isSetinvitation"
        class="friend-invite-grade"
      >
        <div class="friend-invite-grade-top">
          <p class="friend-invite-grade-title">我的成绩</p>
          <div
            @click="$router.push('/activityDescription')"
            class="friend-invite-grade-img-tip"
          >
            <img src="../assets/icon/question.png" />
            <p>活动说明</p>
          </div>
        </div>
        <div class="friend-invite-grade-bottom">
          <div class="friend-invite-grade-bottom-item">
            <p class="tip">{{ inviteNum }}</p>
            <p class="title">邀请人数</p>
          </div>
          <div class="line"></div>
          <div class="friend-invite-grade-bottom-item">
            <p class="tip">{{ ranking }}</p>
            <p class="title">活动排名</p>
          </div>
          <div class="line"></div>
          <div class="friend-invite-grade-bottom-item">
            <p class="tip">{{ topRange }}</p>
            <div
              @click="$router.push('/topList?id=' + eventId)"
              class="friend-invite-grade-bottom-item-next"
            >
              <p class="title">榜单TOP</p>
              <p class="right-arr"></p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="poster"
        ref="poster"
        :style="show ? 'width: 90vw;margin-left:5vw;' : 'width: 100vw'"
      >
        <img
          style="min-height: 50vh;"
          id="sherImg"
          :src="show ? sherUrl : sherImgUrl"
          crossOrigin="anonymous"
        />
        <div
          v-if="!show"
          class="qrcodeCont"
        >
          <vue-qr
            class="qrcode"
            :text="downloadData.url"
            :margin="0"
            :logoSrc="downloadData.icon"
            :logoScale="0.2"
            :size="200"
            backgroundColor='#fff'
          ></vue-qr>
          <p>邀请码</p>
          <p>{{uid}}</p>
        </div>
      </div>
      <div class="popupfont">长按图片保存到相册</div>
    </div>

    <div class="friend-bottom-list">
      <div
        @click="copyUrl"
        :data-clipboard-text="downloadData.url"
        id="copy_text"
        class="bottomCenter"
      ><img src="../assets/icon/invitationFriends/copy.png" />
        <p class="friend-bottom-font">复制链接</p>
      </div>
    </div>
  </div>
</template>
<script>
import config from '../config'
import api from '../api/index-client'
import Html2Canvas from 'html2canvas';
import Clipboard from 'clipboard';
import VueQr from 'vue-qr'
import Vue from 'vue'
import { Popup } from 'vant';
Vue.use(Popup);
export default {
  data () {
    return {
      isSetinvitation: true,
      eventId: null,
      inviteNum: '',
      sherImgUrl: '',
      ranking: '',
      topRange: '',
      sherUrl: '',
      show: true,
      uid: '',
      downloadData: {
        url: config.locationHref + '?spread=' + this.uid,
        icon: require('../assets/icon/invitationFriends/metaicon.png'),
      }
    }
  },
  components: {
    VueQr
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.show = false
    this.getMyInfo()
  },
  methods: {
    copyUrl () {
      let clipboard = new Clipboard('#copy_text');
      clipboard.on('success',e => {
        this.$toast({
          message: '复制成功',
          icon: require('../assets/icon/toast-success.png'),
        });
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error',e => {
        // 不支持复制
        this.$toast({
          message: '该浏览器不支持自动复制',
          icon: require('../assets/icon/toast-error.png'),
        });
        // 释放内存
        clipboard.destroy()
      })
    },
    toBase64 () {
      let poster = this.$refs.poster
      let width = poster.clientWidth
      let height = poster.clientHeight - 2
      let scale = window.devicePixelRatio;
      // 调整canvas清晰度
      let canvas = document.createElement('canvas')
      canvas.width = width * scale
      canvas.height = height * scale
      canvas.style.width = width
      canvas.style.height = height
      canvas.style.background = '#1c1c1c'
      poster.style.background = '#1c1c1c'
      Html2Canvas(poster,{
        useCORS: true, // 允许跨域图片
        logging: false,
        width: width,
        height: height,
        scale: scale,
        canvas: canvas,
        background: '#1c1c1c',
      }).then((canvas) => {
        // 返回图片的二进制数据
        this.sherUrl = canvas.toDataURL("image/png")
        this.show = true
        this.$store.commit('HIDE_APPLOADING')
      })
    },
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.uid = result.data.data.uid
            this.$set(this.downloadData,"url",config.locationHref + '?spread=' + result.data.data.uid)
            this.getInviteList()
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取邀请信息
    getInviteList () {
      api
        .get('event/getUserEventInfo')
        .then(result => {
          if (result.data.success) {
            this.description = result.data.data.description
            this.inviteNum = result.data.data.inviteNum
            this.ranking = result.data.data.ranking
            this.topRange = result.data.data.topRange
            this.eventId = result.data.data.eventId
            this.sherImgUrl = result.data.data.img
            setTimeout(() => {
              this.toBase64()
            },1000)

            if (result.data.data.isDefault == 1) {
              this.isSetinvitation = false
              this.$store.commit('HIDE_ISSETINVITATION')
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
  }
}
</script>
<style lang="less" scoped>
.friend-bottom-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  .friend-bottom {
    height: calc(100vh - 163px);
    margin-top: 62px;
    overflow-y: auto;
    padding-bottom: 100px;
  }
  .friend-invite-grade {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 10px;
    margin-bottom: 12px;
    background: #262728;
    border-radius: 8px;
    .friend-invite-grade-top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      border-bottom: 1px solid #343434;
      .friend-invite-grade-title {
        font-size: 14px;
        font-family: lantingheiweight;
        font-weight: 400;
        color: #ffffff;
        line-height: 19px;
        margin-left: 12px;
      }
      .friend-invite-grade-img-tip {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
        p {
          font-size: 12px;
          font-family: lantingheiweight;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
          margin-right: 12px;
        }
      }
    }
    .friend-invite-grade-bottom {
      width: 100%;
      height: 95px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .line {
        width: 1px;
        height: 74px;
        background: #343434;
        margin: 0 26px;
      }
      .friend-invite-grade-bottom-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tip {
          font-size: 24px;
          font-family: SFUIDisplay-Bold, SFUIDisplay;
          font-weight: bold;
          color: #ffffff;
          line-height: 29px;
          margin-bottom: 19px;
        }
        .title {
          font-size: 14px;
          font-family: lantingheiweight;
          font-weight: 400;
          color: #bcbcbc;
          line-height: 19px;
        }
        .friend-invite-grade-bottom-item-next {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        .right-arr {
          width: 6px;
          height: 6px;
          border-top: 1px solid rgba(255, 255, 255, 0.65);
          border-right: 1px solid rgba(255, 255, 255, 0.65);
          transform: rotate(45deg);
          margin-left: 5px;
        }
      }
    }
  }
  .poster {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .friend-center-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-top: 1.375rem;
      padding-bottom: 1rem;
      background-image: url('../assets/icon/invitationFriends/background.png');
      background-repeat: repeat;
    }
    .qrcodeCont {
      width: 25.23vw;
      height: 35.3835vw;
      background-color: #dbdbdb;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: 0.25rem;
      position: absolute;
      bottom: 6.1538vw;
      right: 6.1538vw;
    }
    .qrcode {
      background: #ffffff;
      width: 23vw;
      padding: 1.11vw;
      margin-bottom: 3px;
      border-radius: 0.25rem;
    }
    p {
      font-size: 12px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #0a0b0b;
      line-height: 14px;
    }
  }
  img {
    width: 100%;
  }
  .friend-bottom-title {
    text-align: left;
    font-size: 1.125rem;
    font-weight: bold;
    color: #ffffff;
    font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  }
  .friend-bottom-tip {
    font-size: 0.75rem;
    font-family: lantingheiweight;
    font-weight: 600;
    color: #cccccc;
    line-height: 1.625rem;
    word-break: break-all;
  }
  .friend-bottom-list {
    width: 100vw;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    height: 101px;
    background: linear-gradient(180deg, #5a5a5a 0%, #3a3a3a 100%);
    border-radius: 8px 8px 0px 0px;
    img {
      width: 3.125rem;
      margin-bottom: 0.75rem;
    }
    .friend-bottom-font {
      font-size: 0.9375rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #cccccc;
      line-height: 1.3125;
    }
  }
}
.popupfont {
  width: 100%;
  font-size: 14px;
  font-family: lantingheiweight;
  font-weight: 500;
  color: #f1f1f1;
  line-height: 19px;
  letter-spacing: 1px;
  text-align: center;
  margin: 15px 0 20px 0;
}
.van-popup {
  background: none;
}
.bottomCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>